/* Slideshow */
.SlideShow>img {
    object-fit: cover;
    width: 100%;
    padding: 4rem 1rem 1rem 0;
}

/* Category */
.category {
    display: flex;
    gap: 1rem;
    align-items: center;
    background: var(--delft-blue);
    width: 100%;
    color: var(--sunset);
    scrollbar-width: 2px;
    padding: 0.5rem 2rem;
    box-shadow: 0px 4px 12px 2px;
    overflow-x: auto;
}

.category div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.category img {
    width: 85px;
    border-radius: 100%;
    aspect-ratio: 1;
    height: 100%;
    padding: 0;
    border: 4px solid var(--sunset);
}

.category::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

.category p {
    color: var(--sunset);
    font-weight: 700;
    line-height: normal;
    text-align: center;
    text-wrap: nowrap;
    padding: 0.15rem 0.5rem;
    font-size: 0.85rem;
    border-radius: 1rem;
    border: 2px solid var(--sunset);
    cursor: pointer;
    text-transform: uppercase;
}

.category div:hover p {
    background-color: var(--cambridge-blue);
    color: var(--delft-blue);
    border-color: var(--cambridge-blue);
    transition: all 200ms ease-in-out;
}

.category div:hover img {
    border: 4px solid var(--cambridge-blue);
    transition: all 200ms ease-in-out;
}

/* Why */
.Why{
    display: flex;
    padding: 3rem;
    justify-content: space-evenly;
    align-items: flex-start;
    background: var(--eggshell);
    box-shadow: 0px 4px 8px 4px;
}

.Why>div{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.Why svg{
    font-size: 3.5rem;
    color: var(--delft-blue);
}

.Why p{
    font-weight: 300;
    font-size: 1.25rem;
    color: var(--delft-blue);
    text-align: center;
}

/* Showcase */
.Showcase {
    height: calc(300px + 2rem);
    background: var(--cambridge-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 4px var(--delft-blue);
    z-index: 2;
}

.Showcase .single {
    height: 300px;
    width: 175px;
    object-fit: cover;
    padding: 0;
    border-radius: 1rem;
    margin-right: 2rem;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: 200ms ease-in-out;
}

.Showcase .single:hover {
    transform: scale(1.025);
}

.Showcase .single svg {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: var(--eggshell);
    padding: 0.25rem;
    color: var(--burnt-sienna);
    border-radius: 100%;
    font-size: 2rem;
    cursor: pointer;
}

/* Review */
.Review {
    display: flex;
    padding: 2rem 0rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    background: var(--delft-blue);
    color: var(--sunset);
    box-shadow: inset 0px 0px 20px 10px var(--cambridge-blue);
}

.Review>p {
    color: var(--sunset);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Review>div {
    width: 70%;
}

.singleReview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.singleReview img {
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    max-height: 4rem;
    padding: 0;
    width: auto;
}

.singleReview p {
    text-align: center;
    text-overflow: ellipsis;
    text-wrap: balance;
}

@media (max-width: 768px) {
    .category img{
        width: 65px;
    }
    .Why svg{
        font-size: 2rem;
    }
    .Why p{
        font-size: 0.9rem;
        font-weight: 400;
    }
    .Why div{
        gap: 1rem;

    }
    .Why {
        padding: 2rem 1rem;
        gap: 0.5rem;
    }

    .category {
        padding: 1rem;
    }
}