.Login {
    padding: 4rem 2rem;
}

.Login h2 {
    font-size: 2rem;
    color: var(--delft-blue);
    width: 100%;
    text-align: left;
    line-height: 85%;
}

.Login>div {
    display: flex;
    justify-content: center;
    align-items: stretch;
    background: var(--cambridge-blue);
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 0 12px 6px;
    max-height: 600px;
    border: 4px solid var(--delft-blue);
}

.Login img {
    object-fit: contain;
    max-height: 610px;
    border: 2px solid var(--delft-blue);
}

.Login>div>div {
    width: 70%;
    height: 100%;
    border-right: 4px solid var(--delft-blue);
}

.Login>div>form {
    padding: 1rem;
    background-color: var(--delft-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--sunset);
    width: 30%;
    gap: 1rem;
}

.Login>div>form>div {
    width: 100%;
}

.Login>div>form>button {
    margin: 1rem 0;
}

.Login>div>form>div:nth-child(4) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.Login a {
    text-align: center;
    padding: 0.25rem 1rem;
    background-color: var(--eggshell);
    color: var(--delft-blue);
    font-size: 1rem;
    border: 0.125rem solid var(--delft-blue);
    border-radius: 100px;
    font-weight: 800;
}

@media (max-width: 768px) {
    .Login>div>div {
        width: 0;
    }

    .Login>div>form {
        width: 100%;
    }
}