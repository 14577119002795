.OrderDetail {
    padding: 2rem;
    padding-top: 0;
}

.OrderDetail>h3 {
    font-size: 2rem;
    color: var(--delft-blue);
    background: var(--eggshell);
    padding: 1rem;
    border-radius: 0 0 1rem 1rem;
    width: 100%;
    box-shadow: 0px 0 12px 4px;
}

.Order>div {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
    padding: 0 2rem;
}

.OrderDetail>div {
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;
    border: 4px solid var(--delft-blue);
    min-height: 50vh;
    color: var(--delft-blue);
    box-shadow: 0px 4px 8px 4px var(--cambridge-blue);
    background-color: var(--eggshell);
    margin-top: 1rem;
}

.imagePart {
    display: flex;
    flex-direction: column;
    border: 4px solid var(--delft-blue);
    border-radius: 1.5rem;
    overflow: hidden;
    gap: 1rem;
    width: 100%;
    height: fit-content;
}

.imagePart>div:nth-child(2) {
    border: 4px solid var(--delft-blue);
    border-radius: 1.25rem;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagePart>div>button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
}


.OrderDetail ul {
    margin-left: 1.5rem;
}

.OrderDetail li {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 1rem;
}

.OrderDetail>div h3 {
    width: 150px;
}

.OrderDetail>div h4 {
    width: calc(100% - 150px);
}

.OrderDetail>div>div {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.detailPart {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .OrderDetail>div {
        flex-direction: column;
        padding: 1rem;
    }

    .OrderDetail>div>div {
        flex-direction: column-reverse;
    }

    .OrderDetail {
        padding: 1rem;
        padding-top: 0;
    }
}