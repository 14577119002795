/* Product */
.ProductSlider {
    display: flex;
    overflow: hidden;
    padding: 1rem 2rem 1rem 2rem;
    background: var(--sunset);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
}

.ProductSlider>p {
    color: var(--dark-green, hsla(234, 20%, 30%, 1));
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: justify;
    text-transform: uppercase;
}

.ProductSlider>div {
    overflow: hidden;
    border: 2px solid var(--delft-blue);
    border-radius: 1.5rem;
    align-self: stretch;
    background: var(--eggshell);
}

.ProductSlider>div>div {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: flex-start;
    overflow: auto;
    padding: 0.25rem
}

.ProductSlider button {
    width: 100%;
    padding: 0.25rem;
}

@media (max-width: 768px) {
    .ProductSlider {
        padding: 1rem;
    }
}