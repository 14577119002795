.Footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    color: var(--sunset);
    box-shadow: 0px -4px 8px 4px var(--delft-blue);
    z-index: 2;
}

.Footer a,
.Footer p {
    all: unset;
    font-size: 1.05rem;
    font-weight: 300;
    cursor: pointer;
}

.Footer>div:nth-child(1) {
    width: 100%;
    padding: 1rem;
    background: var(--cambridge-blue);
}


.top {
    display: flex;
    padding: 2rem 2rem;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--delft-blue);
    color: var(--sunset);
    border-radius: 1rem;
    box-shadow: inset 0px 0px 6px 2px black;
}

.top>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    border-right: 1px solid var(--cambridge-blue);
    gap: 1rem;
}

.top>div:nth-child(1) a{
    width: 100%;
}

.top>div:nth-child(1)>div {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.top>div:nth-child(2)>div>div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.top>div:nth-child(2)>div,
.top>div:nth-child(3)>div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.top>div:nth-child(2),
.top>div:nth-child(3) {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 1rem;
    border-right: 1px solid var(--cambridge-blue);
    width: 100%;
    padding: 0.5rem;
}

.top>div:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top img {
    max-height: 175px;
    object-fit: contain;
    width: 100%;
}

.top>div {
    width: 100%;
    padding: 0 1rem;
}

.top h2 {
    text-align: left;
    font-size: 1.35rem;
}

.top>div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.top svg {
    font-size: 2rem;
}

.down {
    display: flex;
    padding: 0.625rem 2rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: var(--delft-blue);
    color: var(--eggshell);
}

.down>p:nth-child(2) {
    text-align: right;
}

@media (max-width: 768px) {
    .top {
        flex-direction: column;
        padding: 1rem 0;
    }

    .top>div:nth-child(1) {
        align-items: flex-start;
        padding: 1rem 2rem;
        border-bottom: 1px solid;
    }

    .top>div:nth-child(2)>div>div {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .top>div:nth-child(2)>div,
    .top>div:nth-child(3)>div {
        width: 100%;
    }

    .top>div:nth-child(2),
    .top>div:nth-child(3) {
        padding: 1rem 2rem;
        border-bottom: 1px solid;
    }

    .top>div:nth-child(4) {
        justify-content: flex-start;
        padding: 1rem 2rem;
    }

    .down {
        padding: 0.625rem 0.85rem;
        flex-direction: column;
    }

    .down>p {
        font-size: 0.85rem;
    }
}