.Review {
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--delft-blue);
    color: var(--sunset);
}

.Review>h3 {
    font-size: 1.5rem;
    font-weight: 700;
}

.Review>div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.allReviews {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.allReviews>div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    background: var(--eggshell);
    color: var(--delft-blue);
    border-radius: 1rem;
    gap: 1rem;
    box-shadow: 5px 5px 4px 0px var(--cambridge-blue);
}

.allReviews>div>img {
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    max-height: 4rem;
    object-fit: cover;
}


@media (max-width: 768px) {
    .Review {
        padding: 2rem 1rem;
    }
}