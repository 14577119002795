.Cart {
    padding: 2rem;
    padding-top: 0;
}

.Cart>h3 {
    font-size: 2rem;
    color: var(--delft-blue);
    background: var(--eggshell);
    padding: 1rem;
    border-radius: 0 0 1rem 1rem;
    width: 100%;
    box-shadow: 0px 0 12px 4px;
}

.Cart>div {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
    padding: 0 2rem;
}

.detail {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: 4px solid var(--delft-blue);
    border-radius: 1.25rem;
    min-width: 250px;
    background: var(--sunset);
}

.detail>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .Cart {
        padding: 1rem;
        padding-top: 0;
    }

    .Cart>div {
        flex-direction: column;
        padding: 1rem;
        margin-top: 0;
    }

    .detail {
        width: 100%;
    }
}