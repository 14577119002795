/* Product Detail */
.ProductDetail {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    gap: 1rem;
}

.ProductDetail>div {
    width: 100%;
}

.ProductDetail>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    border: 2px solid var(--delft-blue);
    border-radius: 1.75rem;
    overflow: hidden;
    gap: 1rem;
    background: var(--eggshell);
    box-shadow: 0px 0px 10px 2px var(--delft-blue);
}

.ProductDetail>div:nth-child(1)>div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProductDetail>div:nth-child(1)>div>button {
    width: 100%;
    height: 100%;
    border: 2px solid var(--delft-blue);
}

.ProductDetail>div:nth-child(1)>div>button:nth-child(1) {
    border-radius: 6.5rem 0 0 6.5rem;
}

.ProductDetail>div:nth-child(1)>div>button:nth-child(2) {
    border-radius: 0 6.5rem 6.5rem 0;
}

.ProductDetail>div:nth-child(2) {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    background: var(--sunset);
    color: var(--delft-blue);
    border-radius: 1rem;
    box-shadow: 5px 10px 6px 3px var(--delft-blue);
}

.ProductDetail h4 {
    font-weight: 400;
}

.ProductDetail ul {
    margin-left: 1rem;
}

.ProductDetail .thumbnailClass img{
    object-fit: contain;
}
.ProductDetail .originalClass img{
    object-fit: contain;
}

.priceTag {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: flex-end;
}

.priceTag>span {
    text-decoration: line-through;
    font-weight: 700;
}


/* Rating Review */
.RatingAndReview {
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--delft-blue);
    color: var(--sunset);
}

.RatingAndReview>h3 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--sunset);
}

.RatingAndReview>div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.allReviews {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.allReviews>div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    background: var(--eggshell);
    color: var(--delft-blue);
    border-radius: 1rem;
    gap: 1rem;
}

.allReviews>div>img {
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    max-height: 4rem;
    object-fit: cover;
}


@media (max-width: 768px) {
    .ProductDetail {
        flex-direction: column;
    }

    .RatingAndReview {
        padding: 2rem 1rem;
    }
}

@media (max-width: 768px) {
    .ProductDetail {
        padding: 1rem;
    }

    .ProductDetail>div:nth-child(2) {
        padding: 1rem;
    }
}
