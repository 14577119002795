.NavBar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--sunset);
    padding: 1rem 2.5%;
    position: relative;
    gap: 0.5rem;
    box-shadow: 0 2px 20px 0px;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 200px;
    background-color: var(--delft-blue);
    transition: all 300ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;
    border-left: 2px solid black;
    z-index: 9999;
}

.showSidebar {
    right: 0;
    gap: 1rem;
    overflow-y: auto;
}

.hideSidebar {
    right: -200px;
}

.sidebar>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.sidebar button {
    width: 100%;
    color: var(--delft-blue);
}

.show {
    display: none !important;
}

.NavBar img {
    max-height: 64px;
    max-width: 75%;
    cursor: pointer;
    padding: 0 0.5rem;
}

.MainArea {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MainArea>div {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70%;
    padding: 0 1rem;
    gap: 0.25rem;
    color: var(--delft-blue);
}

.SearchArea {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
}

.SearchArea input {
    height: 100%;
    border-radius: 6.5rem 0 0 6.5rem;
}

.SearchArea button {
    height: 100%;
    border-radius: 0 6.5rem 6.5rem 0;
    padding: 0.2rem 0.3rem;
}

.SearchArea svg {
    color: var(--delft-blue);
}

.MainArea svg {
    font-size: 1.5rem;
    cursor: pointer;
}

@media (max-width:768px) {
    .hide {
        display: none !important;
    }

    .show {
        display: block !important;
    }
}